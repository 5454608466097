.edit-substitute-content-component {
    margin-top: 10px;

    .send-buttons {
        display: flex;
        padding-top: 20px;
        flex-direction: row;
        justify-content: space-between;
    }

    .form {
        gap: 20px;
    }
}
