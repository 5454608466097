@import "shared/constants.scss";
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.flexparent {
  display: flex;
}

.flexcenter {
  display: flex;
  align-items: center;
}

.flexcentercenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-down {
  display: flex;
  flex-direction: column;
}

.flexchild {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex-gap-5 {
  display: flex;
  gap: 5px;
}

.flex-gap-10 {
  display: flex;
  gap: 10px;
}

.flex-gap-20 {
  display: flex;
  gap: 20px;
}

.clickable {
  cursor: pointer;
}

.header-text {
  font-size: 18px;
}

.page-item {
  padding: 0 10px;
  .right-padding {
    padding-right: 5px;
  }
}

.text-item {
  padding: 0 10px;
}

.vertical-padding {
  padding-top: 20px;
}

.small-vertical-padding {
  padding-top: 10px;
}

.display-linebreak {
  white-space: pre-line;
}

h4 {
  margin-bottom: 10px;
}

.centered-buttons {
  padding: 0 10px;
}

.parent-pos {
  position: relative;
  padding-bottom: 80px;
}

.bottom-element-bekreft {
  background-color: white;
  z-index: 1;
  position: fixed;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  bottom: 0;
  right: 0;
  left: 0;
}

.bottom-inner {
  width: 100%;
  max-width: 800px;
  position: fixed;
  bottom: 0;

  .bottom-element {
    display: flex;
    background-color: white;
    z-index: 1;
    padding-bottom: 10px;
    padding-top: 10px;
    justify-content: space-between;

    .right-element {
      align-self: flex-end;
      margin-right: 15px;
    }
    .left-element {
      align-self: flex-start;
      margin-left: 15px;
    }
  }
}

p {
  margin-top: 0px;
}

.extra-inline-padding {
  padding: 0px 20px;
}

.centered {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.same-line-wrapper {
  padding-top: 10px;
  display: flex;
  .same-line-form {
    flex: 1;
  }
  .same-line-text {
    padding-right: 10px;
    line-height: 35px;
  }
  .same-line-date {
    flex: 1;
    align-self: center;
    font-size: 17px;
    padding-left: 30px;
  }
}

.mb40 {
  margin-bottom: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mr40 {
  margin-right: 40px;
}

.ml40 {
  margin-left: 40px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mr20 {
  margin-right: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt10 {
  margin-top: 10px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

.mt5 {
  margin-top: 5px;
}

.mr5 {
  margin-right: 5px;
}

.ml5 {
  margin-left: 5px;
}

.m5 {
  margin: 5px;
}

.pt40 {
  padding-top: 40px;
}

.pt20 {
  padding-top: 20px;
}

.pt10 {
  padding-top: 10px;
}

.pr5 {
  padding-right: 5px;
}

.pl5 {
  padding-left: 5px;
}

.pt5 {
  padding-top: 5px;
}
.pt3 {
  padding-top: 3px;
}

.m10 {
  margin: 10px;
}

.g5 {
  gap: 5px;
}

.g10 {
  gap: 10px;
}

.fullWidth {
  width: 100%;
}

.page-component {
  flex-grow: 1;
}

.one-line-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


